<!-- 用户反馈 -->
<template>
  <div class="wrap">
    <div class="main_content">
      <p>意见与建议</p>
      <el-input
        type="textarea"
        placeholder="请输入内容"
        v-model="textarea"
        maxlength="100"
        resize="none"
        show-word-limit
      ></el-input>
      <span>&emsp;*仅可输入一百个字</span>
      <el-button class="btn">提交</el-button>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      // 意见框
      textarea: '',
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
  min-width: 1100px;
}
.main_content {
  background-color: #fff;
  box-shadow: $box-shadow;
  border-radius: $radius;
  height: 100%;
  padding: 10px 0 50px 0;
}
.main_content p {
  padding-left: 30px;
  padding-top: 0;

  font-size: 22px;
  color: #333;
}
div /deep/ .el-textarea {
  padding-left: 200px;
  width: 500px;
  height: 200px;
}
div /deep/ .el-textarea__inner {
  width: 500px;
  height: 200px;
}
span {
  font-size: 12px;
  color: #d21717;
}
div /deep/ .el-button {
  display: block;
  margin: 20px 0 0 630px;

  color: #fff;
}
</style>
